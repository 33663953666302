<template>
  <div style="background: white; border-radius: 5px" v-loading="loading">
    <HeaderProductionManagement :query="query" />
    <hr/>
    <div class="grid-filter">
      <div class="display-flex-column">
        <span>Khách hàng</span>
        <el-input
            clearable
            placeholder="Tìm theo tên, số điện thoại KH"
            suffix-icon="el-icon-search"
            v-model="query.keyword"
            @change="handleQueryFilter"
        >
        </el-input>
      </div>
      <div class="display-flex-column">
        <span>Trung tâm</span>
        <el-select v-model="query.center_id" filterable clearable placeholder="Chọn trung tâm" @change="handleQueryFilter">
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="display-flex-column">
        <span>Chi nhánh</span>
        <el-select v-model="query.branch_id" filterable multiple clearable placeholder="Chọn chi nhánh" @change="handleQueryFilter" collapse-tags>
          <el-option
              v-for="item in branches"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="display-flex-column">
        <span>Khóa học cần mở lớp</span>
        <el-select v-model="query.course_id" filterable clearable placeholder="Chọn khóa học cần xếp" @change="handleQueryFilter">
          <el-option
              v-for="item in courses"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="display-flex-column">
        <span>Trạng thái học viên</span>
        <el-select v-model="query.status_student" style="width: 100%" clearable placeholder="Chọn trạng thái học viên" @change="handleQueryFilter">
          <el-option
              v-for="item in statusOption"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="display-flex-column">
        <span>Thời gian có thể đi học</span>
        <el-date-picker
            style="width: 100%"
            v-model="query.time_available"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            :default-value="new Date()"
            @change="handleQueryFilter"
            placeholder="Chọn thời gian">
        </el-date-picker>
      </div>
      <div class="display-flex-column">
        <span>Lịch học mong muốn</span>
        <el-select v-model="query.desired_schedule" style="width: 100%" filterable clearable placeholder="Chọn lịch học mong muốn" @change="handleQueryFilter" collapse-tags>
          <el-option
              v-for="item in timeManagers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="table-responsive" style="padding: 24px">
      <table class="table" style="text-align: center;">
        <thead>
        <tr style="border: 1px solid #EBEDF3">
          <th scope="col" style="background: #F8F8F8; min-width: 100px; text-align: start">Mã học viên</th>
          <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 280px;">Họ tên khách hàng</th>
          <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 120px">Số điện thoại</th>
          <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 240px">Chi nhánh học</th>
          <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 220px">Khóa học</th>
          <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 180px">Trạng thái học viên</th>
          <th scope="col" style="background: #F8F8F8; text-align: start; display: flex; align-items: center; min-width: 220px; border-bottom: 0; height: 100%">Thời gian có thể đi học
            <el-tooltip placement="top">
              <div slot="content">Với học viên đang học khóa dưới, thời gian có <br>thể đi học đang hiển thị là thời
                gian lớp kết thúc.
              </div>
              <el-button style="background: transparent; border: none; height: 6px; margin-top: -14px; margin-left: -15px"><i class="el-icon-question" style="color: #00aff0"></i>
              </el-button>
            </el-tooltip>
          </th>
          <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 280px">Lịch học mong muốn</th>
        </tr>
        </thead>
        <tbody v-if="dataTable?.length > 0">
        <tr v-for="item of dataTable" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
          <td style="text-align: start">{{ item?.code }}</td>
          <td style="text-align: start" :title="item?.name">
            <span class="line-clamp-1">{{ item?.name }}</span>
          </td>
          <td style="text-align: start">{{ item?.phone }}</td>
          <td style="text-align: start" :title="item?.branch">
            <span class="line-clamp-1">{{ item?.branch }}</span>
          </td>
          <td style="text-align: start" :title="item?.course">
            <span class="line-clamp-1">{{ item?.course }}</span>
          </td>
          <td style="text-align: start">
            <span :class="item?.colorStatus" style="padding: 5px 8px; border-radius: 5px; white-space: nowrap">{{ item?.status }}</span>
          </td>
          <td style="text-align: start">{{ item?.timeStudy }}</td>
          <td style="text-align: start" :title="item?.schedule">
            <span class="line-clamp-1">{{ item?.schedule }}</span>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="!loading && dataTable?.length === 0">
        <tr style="word-break: break-word; white-space: nowrap; position: relative; height: 40px">
          <div style="position: absolute; left: 50%; translate: -50%; top: 10px;">
            <div style="line-height: 25px !important;">Chưa có dữ liệu</div>
          </div>
        </tr>
        </tbody>
      </table>
      <div v-if="dataTable?.length > 0">
        <div class="edu-paginate mx-auto text-center mt-10 mb-5"
             style="display: flex; justify-content: center; align-items: center; height: 50px">
          <paginate
              v-model="page"
              :page-count="lastPage"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
          >
          </paginate>
        </div>
        <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
          Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{ this.total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import HeaderProductionManagement from "@/view/pages/production-management/component/Header.vue";
import {GET_LIST_PRODUCTION} from "@/core/services/store/course/classes.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {
  GET_CENTER_TAG_E,
  GET_COURSE_LIST,
  GET_SCHEDULE_LIST
} from "@/core/services/store/task-management/task-management.module";

import {statusProduction} from "@/common/define";
import {ProductionModel} from "@/view/pages/production-management/model/productionModel";

export default {
  name: 'ProductionManagement',
  components: {HeaderProductionManagement},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý đơn vị sản xuất"},
      {title: "Nguồn lực học viên mở lớp", name: 'ProductionUnitManagement'},
    ]);
    this.getListProduction()
    this.getCenterTagE()
    this.getListCourse()
    this.getListTimeManager()
    this.getBranchesForCenter()
  },
  data() {
    return {
      loading: true,
      query: {
        keyword: null,
        center_id: null,
        branch_id: [],
        course_id: null,
        status_student: null,
        time_available: null,
        desired_schedule: null,
      },
      centers: [],
      branches: [],
      courses: [],
      timeManagers: [],
      statusOption: statusProduction,
      dataTable: [],
      page: 1,
      lastPage: 1,
      total: 0,
    }
  },
  watch: {
    "query.center_id"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.query.branch_id = [];
        this.query.course_id = null;
        this.query.time_available = null;
      }
    },
  },

  methods: {
    clickCallback(obj) {
      this.page = obj
      this.getListProduction()
    },
    async getListProduction() {
      await this.pullParams()
      this.callApi();
    },
    callApi() {
      const payload = {
        page: this.page,
        keyword: this.query.keyword,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        course_id: this.query.course_id,
        status_student: this.query.status_student,
        time_available: this.query.time_available,
        desired_schedule: this.query.desired_schedule
      }
      this.$store.dispatch(GET_LIST_PRODUCTION, payload).then(res => {
        if (!res.error) {
          this.dataTable = res.data.map(item => {
            return new ProductionModel(item)
          })
          this.total = res.pagination?.total
          this.lastPage = res.pagination?.last_page
          this.page = res.pagination?.current_page
        }
      }).catch(e => {
        console.log(e, 'Get list production error !')
      }).finally(() => {
        this.loading = false
      })
    },
    async handleQueryFilter() {
      this.page = 1
      this.getBranchesForCenter()
      this.getListCourse()
      this.getListTimeManager()
      await this.pushParams()
      this.callApi()
    },
    async pushParams() {
      await this.$router.push({
        name: 'ProductionManagement',
        query : {
          keyword: this.query.keyword,
          center_id: this.query.center_id,
          branch_id: this.query.branch_id,
          course_id: this.query.course_id,
          status_student: this.query.status_student,
          time_available: this.query.time_available,
          desired_schedule: this.query.desired_schedule,
        }
      })
    },
    pullParams() {
      this.query.keyword = this.$route.query.keyword
      this.query.center_id = this.$route.query.center_id ? parseInt(this.$route.query.center_id) : null
      this.query.branch_id = this.$route.query.branch_id ? JSON.parse(`[${this.$route.query.branch_id}]`) : []
      this.query.course_id = this.$route.query.course_id ? parseInt(this.$route.query.course_id) : null
      this.query.status_student = this.$route.query.status_student ? parseInt(this.$route.query.status_student) : null
      this.query.time_available = this.$route.query.time_available
      this.query.desired_schedule = this.$route.query.desired_schedule
    },
    getBranchesForCenter() {
      const payload = {
        limit: 10000,
        center_id: this.query.center_id ?? null
      }
      this.$store.dispatch(GET_BRANCHES, payload).then(res => {
        if (!res.error) {
          this.branches = res.data?.data ?? [];
        }
      }).catch(e => {
        console.log(e, 'get branches error!')
      })
    },
    getListTimeManager() {
      const payload = {
        center_id: this.query.center_id ?? null
      }
      this.$store.dispatch(GET_SCHEDULE_LIST, payload).then(res => {
        if (!res.error) {
          this.timeManagers = res.data ?? [];
        }
      }).catch(e => {
        console.log(e, 'get list time manager error!')
      })
    },
    getListCourse() {
      const payload = {
        center_id: this.query.center_id ?? null,
      }
      this.$store.dispatch(GET_COURSE_LIST, payload).then(res => {
        if (!res.error) {
          this.courses = res.data ?? [];
        }
      }).catch(e => {
        console.log(e, 'get list course error!')
      })
    },
    getCenterTagE() {
      const payload = {
        limit: 10000,
        is_edutalk: 1,
        status: 1,
      }
      this.$store.dispatch(GET_CENTER_TAG_E, payload).then(res => {
        if (!res.error) {
          this.centers = res.data?.data;
        }
      }).catch(e => {
        console.log(e, 'get center error!')
      })
    }
  }
}
</script>

<style lang="css" scoped>
.grid-filter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px 60px;
  padding: 0 20px;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
  gap: 5px
}

.blue {
  background: #0066FF;
  color: white;
}
.yellow {
  background: #FFB800;
}
.pink {
  background: #FFA3A3;
}
</style>